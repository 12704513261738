import './App.css';
import HeroSection from './components/sections/heroSection/heroSection';
import InfoSection from './components/sections/infoSection/infoSection';
import DesktopHeader from './components/header/customHeader';
import ScrollableSection from './components/sections/scrollableSection/scrollableSection';
import AboutSection from './components/sections/aboutSection/aboutSection';
import AdvantagesSection from './components/sections/advantagesSection/advantagesSection';
import FeatureSection from './components/featureSection/featureSection';
import Footer from './components/footer/footer';
import { useEffect, useState, useRef } from 'react';

function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isBackgroundChanged, setIsBackgroundChanged] = useState(false);
  const advantageRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBackgroundChanged(entry.isIntersecting);
      },
      { threshold: 0.01 } // Ajuste o threshold conforme necessário
    );

    if (advantageRef.current) {
      observer.observe(advantageRef.current);
    }

    return () => {
      if (advantageRef.current) {
        observer.unobserve(advantageRef.current);
      }
    };
  }, []);

  return (
    <div className={`App ${isBackgroundChanged ? 'bg-change' : ''}`}>
      <HeroSection isMobile={isMobile}>
        <DesktopHeader isMobile={isMobile}/>
      </HeroSection>
      <InfoSection isMobile={isMobile} />
      <AboutSection isMobile={isMobile} />
      <ScrollableSection isMobile={isMobile} />
      <AdvantagesSection isMobile={isMobile} ref={advantageRef} />
      <FeatureSection />
      <Footer />
    </div>
  );
}

export default App;
