import React, { useEffect, useRef } from "react";
import "./infoSection.scss";
import InfoTabs from "../../infoTabs/infoTabs";

import chartExample from "../../../assets/images/chartExample.svg";
import chartImage from "../../../assets/images/chartImage.png";
import processChart from "../../../assets/images/processChart.png";
import vulnerabilitiesChart from "../../../assets/images/vulnerabilitiesChart.png";

function InfoSection({ isMobile }: { isMobile: boolean }) {
  const vulnerabilitiesChartRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      
      // Fator de parallax (ajustável)
      const parallaxEffect = scrollPosition * 0.1;

      if (vulnerabilitiesChartRef.current) {
        vulnerabilitiesChartRef.current.style.transform = `translate(0%, ${parallaxEffect}px)`;
      }
    };

    // Adiciona o listener de scroll
    window.addEventListener("scroll", handleScroll);

    // Limpa o listener quando o componente desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="infoSection">
      {!isMobile?<>
        <div className="chartContainer">
        <div className="chart">
          <img
            className="background"
            src={chartExample}
            alt="Chart"
          />
          <img
            className="image"
            src={chartImage}
            alt="Chart"
          />
          <img
            className="processChart"
            src={processChart}
            alt="Chart"
          />
          <img
            className="vulnerabilitiesChart"
            src={vulnerabilitiesChart}
            alt="Chart"
            ref={vulnerabilitiesChartRef}  // Ref para o parallax
          />
        </div>
      </div>
      </>:<></>}
      <InfoTabs isMobile={isMobile}  />
    </section>
  );
}

export default InfoSection;
