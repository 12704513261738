import React, { useState, useEffect, useMemo } from "react";
import ScrollTemplate from "../../scrollTemplate/scrollTemplate";
import ScrollTemplateMobile from "../../scrollTemplateMobile/scrollTemplateMobile"; // Novo componente mobile
import "./scrollableSection.scss";

import eyeIcon from "../../../assets/icons/eye.svg";
import linkIcon from "../../../assets/icons/link.svg";
import bugIcon from "../../../assets/icons/bug.svg";
import shieldIcon from "../../../assets/icons/shield.svg";
import alarmIcon from "../../../assets/icons/alarm.svg";
import trophyIcon from "../../../assets/icons/trophy.svg";
import lockIcon from "../../../assets/icons/lock.svg";

import eyeImage from "../../../assets/images/eye.svg";
import tagImage from "../../../assets/images/tag.svg";
import bugImage from "../../../assets/images/bug.svg";
import handCubeImage from "../../../assets/images/handCube.svg";
import clockImage from "../../../assets/images/clock.svg";
import starHeadImage from "../../../assets/images/starHead.svg";
import shieldImage from "../../../assets/images/shield.svg";
import PlaceHolderImage from "../../../assets/images/image_placeholder.png"
import scrollImg1 from "../../../assets/images/scrollImg1.png"
import scrollImg2 from "../../../assets/images/scrollImg2.png"
import scrollImg3 from "../../../assets/images/scrollImg3.png"
import scrollImg4 from "../../../assets/images/scrollImg4.png"
import scrollImg5 from "../../../assets/images/scrollImg5.png"
import scrollImg6 from "../../../assets/images/scrollImg6.png"
import scrollImg7 from "../../../assets/images/scrollImg7.png"
import { useTranslation } from "react-i18next";



const ScrollableSection = (function ScrollableSection({ isMobile }: { isMobile: boolean }, ref: any) {

  const { t } = useTranslation("ScrollableSection");

  const scrollTemplatesData = [
    { color: "#F90192", icon: eyeIcon, scrollableImage: scrollImg1, image: eyeImage, title: t("titles.0"), description: t("descriptions.0") },
    { color: "#343CF9", icon: linkIcon, scrollableImage: scrollImg2, image: tagImage, title: t("titles.1"), description: t("descriptions.1") },
    { color: "#F90192", icon: bugIcon, scrollableImage: scrollImg3, image: bugImage, title: t("titles.2"), description: t("descriptions.2") },
    { color: "#343CF9", icon: shieldIcon, scrollableImage: scrollImg4, image: handCubeImage, title: t("titles.3"), description: t("descriptions.3") },
    { color: "#F90192", icon: alarmIcon, scrollableImage: scrollImg5, image: clockImage, title: t("titles.4"), description: t("descriptions.4") },
    { color: "#343CF9", icon: trophyIcon, scrollableImage: scrollImg6, image: starHeadImage, title: t("titles.5"), description: t("descriptions.5") },
    { color: "#F90192", icon: lockIcon, scrollableImage: scrollImg7, image: shieldImage, title: t("titles.6"), description: t("descriptions.6") }
  ];


  const [currentIndex, setCurrentIndex] = useState(0);
  const [rightValueFixed, setRightValueFixed] = useState(0);

  const [posicaoScroll, setPosicaoScroll] = React.useState(0);

  const [isFixed, setIsFixed] = React.useState(false);
  const [isFixedBottom, setIsFixedBottom] = React.useState(false);

  const imageRef = React.useRef<HTMLImageElement>(null);
  const sectionRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sectionTop = sectionRef.current?.getBoundingClientRect().top!;
    const sectionBottom = sectionRef.current?.getBoundingClientRect().bottom!;

    const threshold = 32;

    const isSectionOver = sectionBottom - 480 < threshold;

    setIsFixedBottom(isSectionOver)
    setIsFixed(sectionTop < threshold);

    const sectionWidth = sectionRef.current?.getBoundingClientRect().width!;

    const clientWIdth = document.querySelector('body')!.clientWidth

    const rightValue = ((clientWIdth - sectionWidth) / 2);

    setRightValueFixed(rightValue);
  }, [posicaoScroll]);

  useEffect(() => {
    const handleScroll = () => {
      setPosicaoScroll(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const stylesContainer = useMemo(() => {
    return { background: scrollTemplatesData[currentIndex].color, right: isFixed ? `${rightValueFixed}px` : '0', }
  }, [isFixed, rightValueFixed, currentIndex]);

  return (
    <section className="scrollable-section" ref={sectionRef}>

      {!isMobile && (
        <div>
          <div className={`img-container ${isFixed && !isFixedBottom ? 'fixed' : ''} ${isFixedBottom ? 'fixed-bottom' : ''}`} ref={imageRef} style={stylesContainer}>
            <img src={scrollTemplatesData[currentIndex].scrollableImage} />
          </div>
        </div>
      )}

      {scrollTemplatesData.map((item, index) => (
        isMobile ? (
          <ScrollTemplateMobile
            key={index}
            color={item.color}
            icon={item.icon}
            image={item.scrollableImage}
            title={item.title}
            description={item.description}
          />
        ) : (
          <ScrollTemplate
            key={index}
            color={item.color}
            icon={item.icon}
            image={item.image}
            title={item.title}
            description={item.description}
            isFirst={index === 0}
            isLast={index === scrollTemplatesData.length - 1}
            posicaoScroll={posicaoScroll}
            setCurrentIndex={setCurrentIndex}
            index={index}
          />
        )
      ))}
    </section>
  );
});

export default ScrollableSection;
