import React, { useEffect, useState } from 'react';
import './footer.scss';

import bigTextFooter from "../../assets/images/bigTextFooter.svg"
import riskSquadLogo from "../../assets/icons/riskSquadLogo.svg"
import { useTranslation } from 'react-i18next';

import closeIcon from "../../assets/icons/close.svg";
import guyGlass from "../../assets/icons/guy-glass2.png";
import circleCheck from "../../assets/icons/circleCheck.svg";

function Footer() {
  const { t } = useTranslation("Header"); // Adicionado o hook de tradução

  const { t: t2 } = useTranslation("Header");


  const [isOpen, setIsOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    linkedin: "",
    message: "",
  });

  const toggleFormOpen = () => {
    setIsOpen(!isOpen);

    setTimeout(() => {
      setIsFormOpen(!isFormOpen);
    }, 160);
  };

  const toggleFormSuccess = () => {
    if (isFormSuccess) {
      setIsFormOpen(!isFormOpen);
    }
    setIsFormSuccess(!isFormSuccess);
  };

  useEffect(() => {
    if (!isFormOpen) {
      setIsFormSuccess(false);
      setIsOpen(false);
    }
  }, [isFormOpen])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent form submission

    console.log('!@# formData: ', formData);

    if (!formData?.email) {
      // alert(t2("Header.validation.emailRequired"));
      return;
    }

    try {
      const response = await fetch('https://risksquad.io/enviar_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(formData).toString()
      });

      if (response.ok) {
        toggleFormSuccess();
      } else {
        alert('Erro ao enviar o email.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao conectar com o servidor.');
    }

  };

  return (
    <>
      <footer className="footer" style={{overflow: 'hidden'}}>
        <div className="footerTop">
          <div className="footerLogo">
            <img src={riskSquadLogo} alt="RiskSquad Logo" style={{width: '242px'}}/>
          </div>
          <button className="footerButton" onClick={toggleFormOpen}>{t("Header.consultButton")}</button>
        </div>

        <hr className="footerSeparator" />

        <div className="footerBottom">
          <span className="footerText">All rights reserved© 2024 Risksquad.</span>
          <div className="footerLinks">
            <a href="https://www.youtube.com" target="_blank" rel="noreferrer">Youtube</a>
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">Linkedin</a>
          </div>
          
        </div>
        <img className="bigTextFooter" src={bigTextFooter} alt="RiskSquad Logo" />
      </footer>
      {isFormOpen && (
        <>
          <div className="backdrop"></div>
          <div className="form-dialog">
            <div className={`dialog-container ${isOpen ? "" : "close"}`}>
              {!isFormSuccess && (
                <>
                  <div className="logo-container">
                    <button className="close-container" onClick={toggleFormOpen}>
                      <img className="close" src={closeIcon} alt="Close Icon" />
                    </button>
                    <img
                      className="logo"
                      src={riskSquadLogo}
                      alt="RiskSquad Logo"
                      style={{ width: "242px" }}
                    />
                  </div>

                  <div className="profile">
                    <img src={guyGlass} alt="Guy with glass" />
                    <div className="text-info">
                      <h3 className="form-title">{t2("Header.formTitle1")}</h3>
                      <h3 className="form-title2" style={{ marginTop: "0px" }}>
                        {t2("Header.formTitle2")}
                      </h3>
                      <p>{t2("Header.formSubtitle")}</p>
                    </div>
                  </div>

                  <form className="form" onSubmit={handleSubmit}>
                    <div className="form-container">
                      <label htmlFor="name">{t2("Header.formFields.name")}</label>
                      <input
                        type="text"
                        id="name"
                        placeholder={t2("Header.placeholders.name")}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-container">
                      <label htmlFor="email">{t2("Header.formFields.email")}</label>
                      <input
                        type="email"
                        id="email"
                        placeholder={t2("Header.placeholders.email")}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-container">
                      <label htmlFor="phone">{t2("Header.formFields.phone")}</label>
                      <input
                        type="tel"
                        id="phone"
                        placeholder={t2("Header.placeholders.phone")}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-container">
                      <label htmlFor="message">
                        {t2("Header.formFields.message")}{" "}
                      </label>
                      <input
                        type="text"
                        id="message"
                        placeholder={t2("Header.placeholders.message")}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-container">
                      <label htmlFor="website">
                        {t2("Header.formFields.website")}{" "}
                        <span>({t2("Header.optional")})</span>
                      </label>
                      <input
                        type="text"
                        id="website"
                        placeholder={t2("Header.placeholders.website")}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-container">
                      <label htmlFor="linkedin">
                        {t2("Header.formFields.linkedin")}{" "}
                        <span>({t2("Header.optional")})</span>
                      </label>
                      <input
                        type="text"
                        id="linkedin"
                        placeholder={t2("Header.placeholders.linkedin")}
                        onChange={handleInputChange}
                      />
                    </div>

                    <button type="submit" className="ctaButton">
                      {t2("Header.send")}
                    </button>
                  </form>
                </>
              )}

              {isFormSuccess && (
                <div className='success-dialog'>
                  <div className='dialog-container'>
                    <div className='logo-container'>
                      <button className="close-container" onClick={toggleFormSuccess}>
                        <img src={closeIcon} alt="burger Icon" />
                      </button>
                    </div>

                    <div className='profile'>
                      <div className='image-container'>
                        <img src={guyGlass} alt="guy glass" />
                        <img className='circle-check' src={circleCheck} alt="circleCheck" />
                      </div>

                      <div className="text-info">
                        <h3>{t2("Header.formSuccessTitle")}</h3>
                        <p>{t2("Header.formSuccessMessage")}</p>
                      </div>

                      <button className="ctaButton" style={{ padding: "0px" }} onClick={toggleFormSuccess}>{t2("Header.closeButton")}</button>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </>
      )}
    </>
    
  );
}

export default Footer;
