import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './header.scss';
import riskSquadLogo from "../../assets/icons/riskSquadLogo.svg";
import riskSquadLogoBlack from "../../assets/icons/riskSquadLogoBlack.svg";
import burgerIcon from "../../assets/icons/burger.svg";
import closeIcon from "../../assets/icons/close.svg";
import brazilFlagIcon from "../../assets/images/brazil-flag.svg";
import usaFlagIcon from "../../assets/images/estados-unidos.png";
import guyGlass from "../../assets/icons/guy-glass2.png";
import circleCheck from "../../assets/icons/circleCheck.svg";

function MobileHeader() {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAnimation, setIsOpenAnimation] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isFormSuccess, setIsFormSuccess] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        website: "",
        linkedin: "",
    });
    const { t, i18n } = useTranslation("Header"); // Usa o namespace `MobileHeader`

    const toggleOpen = () => {
        setIsOpenAnimation(!isOpenAnimation);
        setTimeout(() => {
            setIsOpen(!isOpen);
        }, 150);
    };

    const toggleFormOpen = () => {
        setIsFormOpen(!isFormOpen);
    };

    const toggleFormSuccess = () => {
        if (isFormSuccess) {
            setIsFormOpen(!isFormOpen);
        }
        setIsFormSuccess(!isFormSuccess);
    };

    const toggleLanguage = (lang: any) => {
        i18n.changeLanguage(lang);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault(); // Prevent form submission
        console.log('!@# formData: ', formData);

        if (!formData?.email) {
            alert(t("Header.validation.emailRequired"));
            return;
        }

        try {
            const response = await fetch('https://risksquad.io/enviar_email.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(formData).toString()
            });

            if (response.ok) {
                toggleFormSuccess();
            } else {
                alert('Erro ao enviar o email.');
            }
        } catch (error) {
            console.error('Erro:', error);
            alert('Erro ao conectar com o servidor.');
        }
    };

    return (
        <header className="mobile-header">
            <div className='logo-container'>
                <img src={riskSquadLogo} alt="Risksquad Logo" />
                <button className="burger-container" onClick={toggleOpen}>
                    <img src={burgerIcon} alt="burger Icon" />
                </button>
            </div>

            {(isOpen && !isFormOpen) && (
                <div className={`header-dialog ${isOpenAnimation ? '' : 'close'}`}>
                    <div className='dialog-container'>
                        <div className="pink-element"></div>
                        <div className="blue-element"></div>

                        <div className='logo-container'>
                            <img src={riskSquadLogoBlack} alt="Risksquad Logo" />
                            <button className="close-container" onClick={toggleOpen}>
                                <img src={closeIcon} alt="burger Icon" />
                            </button>
                        </div>

                        <div className='content-container'>
                            <div className="language-container">
                                <button onClick={() => toggleLanguage('pt')}>
                                    <img src={brazilFlagIcon} alt="Brazil Flag" className="flag-icon" />
                                    {t("Header.languageLabelPt")}
                                </button>
                                <button onClick={() => toggleLanguage('en')}>
                                    <img src={usaFlagIcon} alt="USA Flag" className="flag-icon" />
                                    {t("Header.languageLabelEn")}
                                </button>
                            </div>

                            <div className="divider"></div>
                            <button className="ctaButton" onClick={toggleFormOpen}>{t("Header.startTrialButton")}</button>
                        </div>
                    </div>
                </div>
            )}

            {isFormOpen && (
                <div className='form-dialog'>
                    <div className='dialog-container'>
                        <div className='logo-container'>
                            <button className="close-container" onClick={toggleFormOpen}>
                                <img src={closeIcon} alt="burger Icon" />
                            </button>
                        </div>

                        <div className='profile'>
                            <img src={guyGlass} alt="guy glass" />
                            <div className="text-info">
                                <h3>{t("Header.formTitle")}</h3>
                                <p>{t("Header.formSubtitle")}</p>
                            </div>
                        </div>

                        <div className="form">
                            <div className="form-container">
                                <label htmlFor="name">{t("Header.formFields.name")}</label>
                                <input
                                    type="text"
                                    id="name"
                                    placeholder={t("Header.placeholders.name")}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="form-container">
                                <label htmlFor="email">{t("Header.formFields.email")}</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder={t("Header.placeholders.email")}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="form-container">
                                <label htmlFor="phone">{t("Header.formFields.phone")}</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    placeholder={t("Header.placeholders.phone")}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="form-container">
                                <label htmlFor="message">{t("Header.formFields.message")}</label>
                                <input
                                    type="text"
                                    id="message"
                                    placeholder={t("Header.placeholders.message")}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="form-container">
                                <label htmlFor="website">{t("Header.formFields.website")} <span>({t("Header.optional")})</span></label>
                                <input
                                    type="text"
                                    id="website"
                                    placeholder={t("Header.placeholders.website")}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="form-container">
                                <label htmlFor="linkedin">{t("Header.formFields.linkedin")} <span>({t("Header.optional")})</span></label>
                                <input
                                    type="text"
                                    id="linkedin"
                                    placeholder={t("Header.placeholders.linkedin")}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <button className="ctaButton" onClick={handleSubmit}>{t("Header.send")}</button>
                        </div>
                    </div>
                </div>
            )}

            {isFormSuccess && (
                <div className='success-dialog'>
                    <div className='dialog-container'>
                        <div className='logo-container'>
                            <button className="close-container" onClick={toggleFormSuccess}>
                                <img src={closeIcon} alt="burger Icon" />
                            </button>
                        </div>

                        <div className='profile'>
                            <div className='image-container'>
                                <img src={guyGlass} alt="guy glass" />
                                <img className='circle-check' src={circleCheck} alt="circleCheck" />
                            </div>

                            <div className="text-info">
                                <h3>{t("Header.formSuccessTitle")}</h3>
                                <p>{t("Header.formSuccessMessage")}</p>
                            </div>

                            <button className="ctaButton" onClick={toggleFormSuccess}>{t("Header.closeButton")}</button>
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
}

export default MobileHeader;
