import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importa os arquivos de tradução para cada seção
import ptFeatureSection from "./components/featureSection/locales/pt.json";
import enFeatureSection from "./components/featureSection/locales/en.json";
import ptAdvantagesSection from "./components/sections/advantagesSection/locales/pt.json";
import enAdvantagesSection from "./components/sections/advantagesSection/locales/en.json";
import ptScrollableSection from "./components/sections/scrollableSection/locales/pt.json";
import enScrollableSection from "./components/sections/scrollableSection/locales/en.json";
import ptAboutSection from "./components/sections/aboutSection/locales/pt.json";
import enAboutSection from "./components/sections/aboutSection/locales/en.json";
import ptInfoTabs from "./components/infoTabs/locales/pt.json";
import enInfoTabs from "./components/infoTabs/locales/en.json";
import ptHeroSection from "./components/sections/heroSection/locales/pt.json";
import enHeroSection from "./components/sections/heroSection/locales/en.json";
import ptHeader from "./components/header/locales/pt.json";
import enHeader from "./components/header/locales/en.json";

i18n
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "pt",
    interpolation: {
      escapeValue: false,
    },
  });

// Carrega as traduções para os namespaces
i18n.addResourceBundle("pt", "FeatureSection", ptFeatureSection, true, true);
i18n.addResourceBundle("en", "FeatureSection", enFeatureSection, true, true);
i18n.addResourceBundle("pt", "AdvantagesSection", ptAdvantagesSection, true, true);
i18n.addResourceBundle("en", "AdvantagesSection", enAdvantagesSection, true, true);
i18n.addResourceBundle("pt", "ScrollableSection", ptScrollableSection, true, true);
i18n.addResourceBundle("en", "ScrollableSection", enScrollableSection, true, true);
i18n.addResourceBundle("pt", "AboutSection", ptAboutSection, true, true);
i18n.addResourceBundle("en", "AboutSection", enAboutSection, true, true);
i18n.addResourceBundle("pt", "InfoTabs", ptInfoTabs, true, true);
i18n.addResourceBundle("en", "InfoTabs", enInfoTabs, true, true);
i18n.addResourceBundle("pt", "HeroSection", ptHeroSection, true, true);
i18n.addResourceBundle("en", "HeroSection", enHeroSection, true, true);
i18n.addResourceBundle("pt", "Header", ptHeader, true, true);
i18n.addResourceBundle("en", "Header", enHeader, true, true);



export default i18n;
