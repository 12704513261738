import React from 'react';
import { useTranslation } from 'react-i18next';
import './header.scss';
import riskSquadLogo from "../../assets/icons/riskSquadLogo.svg";
import brazilFlagIcon from "../../assets/images/brazil-flag.svg";
import chevronDown from "../../assets/icons/chevronDown.svg";
import usaFlagIcon from "../../assets/images/usa-flag.svg";
import MobileHeader from './mobileHeader';

interface IDesktopHeader {
  isMobile: boolean
  toggleFormOpen?: () => void
}

function DesktopHeader({ isMobile, toggleFormOpen}: IDesktopHeader) {

  const [isContainerOpen, setIsContainerOpen] = React.useState(false);
  const { t, i18n } = useTranslation("Header");
  
  const toggleLanguage = (which: number) => () => {
    const newLang = which ? 'pt' : 'en';
    i18n.changeLanguage(newLang);
  };

  const openLanguageDropdown = () => {
    setIsContainerOpen(!isContainerOpen);
  }

  return isMobile ? (
    <MobileHeader />
  ) : (
    <header className="header">
      <div className="headerContainer">
        <div className="headerLogo">
          <img src={riskSquadLogo} alt="Risksquad Logo" />
        </div>

        <div className='flex' onMouseLeave={() => setIsContainerOpen(false)}>
          <div className={`headerLanguage ${isContainerOpen ? 'active' : ''}`} onClick={openLanguageDropdown}>
            <div>
              <img
                src={i18n.language === 'pt' ? brazilFlagIcon : usaFlagIcon}
                alt={i18n.language === 'pt' ? "Brazil Flag" : "USA Flag"}
                className="flag-icon"
              />
              {i18n.language === 'pt' ? <span>{t("Header.languageLabelPt")}</span> : <span>{t("Header.languageLabelEn")}</span>}
            </div>

            <img
              src={chevronDown}
              alt={"arrow-down"}
              className="arrow-down"
            />

            {isContainerOpen && (
              <div className='language-select-container'>
                <button onClick={toggleLanguage(1)} className="language-select-option">
                  <img
                    src={brazilFlagIcon}
                    alt={"Brazil Flag"}
                    className="flag-icon"
                  />
                  <span>{t("Header.languageLabelPt")}</span>
                </button>
                <button onClick={toggleLanguage(0)} className="language-select-option">
                  <img
                    src={usaFlagIcon}
                    alt={"USA Flag"}
                    className="flag-icon"
                  />
                  <span>{t("Header.languageLabelEn")}</span>
                </button>
              </div>
            )}
          </div>

          <button onClick={toggleFormOpen ?? function(){}} className="headerButton">
            {t("Header.consultButton")}
          </button>
        </div>
      </div>
    </header>
  );
}

export default DesktopHeader;
